const prodConfigs = {
  apiUrl: "https://api.parameters.core-ai.io/api/v1/",
  apiV2Url: "https://api.parameters.core-ai.io/api/v2/",
  env: "prod",
  domain: "core-ai.io",
  clientSideId: "620eacbb1663d70c84ca1b20",
  sdkKey: "sdk-66a7a4a2-f72c-4c67-9bdc-d87a5f399edd",
  missionControlBaseUrl: "https://app.optimaldynamics.io/mc",
};

export default prodConfigs;
